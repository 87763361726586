import parseProduct from '@server/utils/parseLoop/parseProduct';
import parseVariant from '@server/utils/parseLoop/parseVariant';
import type { Loop54Response } from '@server/utils/parseLoop/types';
import type { BaseFilter, EnrichedFilter } from 'types/filter';
import type {
    Loop54AutocompleteResponse,
    Loop54AutocompleteResponseLink,
} from 'types/loop54';
import type { Product, Variant } from 'types/product';

type Props = {
    filter?: (BaseFilter | EnrichedFilter)[];
};

export const AUTOCOMPLETE_ENDPOINT = 'autoComplete';

export const PRODUCTS_BY_CATEGORY_ENDPOINT = 'getEntitiesByAttribute';
export const COMPLEMENTARY_PRODUCTS_ENDPOINT = 'getComplementaryEntities';
export const SEARCH_ENDPOINT = 'search';

export const parseAutocomplete = (
    response: Loop54Response,
): Loop54AutocompleteResponse => {
    const { customData, queries, scopedQuery } = response;
    const brands =
        customData?.brandResults.results.items.map(({ attributes }) =>
            attributes.reduce(
                (a, b) => ({
                    ...a,
                    [b.name]: b.values[0],
                }),
                {} as Loop54AutocompleteResponseLink,
            ),
        ) ?? [];
    const categories =
        customData?.categoryResults.results.items.map(({ attributes }) =>
            attributes.reduce(
                (a, b) => ({
                    ...a,
                    [b.name]: b.values[0],
                }),
                {} as Loop54AutocompleteResponseLink,
            ),
        ) ?? [];
    const designers =
        customData?.designerResults.results.items.map(({ attributes }) =>
            attributes.reduce(
                (a, b) => ({
                    ...a,
                    [b.name]: b.values[0],
                }),
                {} as Loop54AutocompleteResponseLink,
            ),
        ) ?? [];
    const scopes =
        scopedQuery?.scopes.map((item) => ({
            query: scopedQuery.query,
            category: item,
        })) ?? [];
    return {
        brands,
        categories,
        designers,
        scopes,
        queries: queries?.items.map((i) => i.query) ?? [],
    };
};

export const parseResult = (
    response: Loop54Response,
    market: string,
): { product: Product; variants: Variant[]; attributes: Map<string, any> } => {
    const { results } = response;
    const { items: _items } = results || {};

    const products = _items.filter((item) => item.type === 'Product');
    const _variants = _items.filter((item) => item.type === 'Variant');

    const [product] = products.map(parseProduct);
    const variants = _variants.map(parseVariant);

    const attributes = new Map<string, any>();
    if (!product?.variantAttributes?.length)
        return {
            product,
            variants,
            attributes,
        };

    for (let i = 0; i < variants.length; i += 1) {
        const {
            price,
            stock,
            Flags = [],
            campaignPrice,
            stockStatus,
        } = variants[i];
        const campaign = Flags.includes(`campaign_${market}`);
        let path = '';
        for (let j = 0; j < product.variantAttributes?.length; j += 1) {
            const { key } = product.variantAttributes[j];
            const id = `${path}${key}=${variants[i][key]}`;
            if (attributes.has(id)) {
                const variant = attributes.get(id) as any;
                attributes.set(id, {
                    ...variant,
                    price: [...new Set([...variant.price, price])],
                    stock: [...new Set([...variant.stock, stock ?? false])],
                    campaign: [
                        ...new Set([...variant.campaign, campaign ?? false]),
                    ],
                    stockStatus: [
                        ...new Set([...variant.stockStatus, stockStatus]),
                    ],
                    ...(campaignPrice && {
                        campaignPrice: [
                            ...new Set([
                                ...(variant?.campaignPrice
                                    ? variant.campaignPrice
                                    : []),
                                campaignPrice,
                            ]),
                        ],
                    }),
                });
            } else {
                attributes.set(id, {
                    price: [price],
                    stock: [stock ?? false],
                    campaign: [campaign ?? false],
                    stockStatus: [stockStatus],
                    ...(campaignPrice && { campaignPrice: [campaignPrice] }),
                });
            }
            path = `${id}&`;
        }
    }

    return {
        product,
        variants,
        attributes,
    };
};

export const parseResults = (response: Loop54Response, props?: Props) => {
    const { results, relatedResults } = response;
    const { facets, items: _items, count } = results || {};
    const items = _items?.map(parseProduct);
    const relatedItems = relatedResults?.items?.map(parseProduct) ?? [];
    const filter: EnrichedFilter[] = [];

    for (let i = 0; i < facets?.length; i += 1) {
        const _filter = props?.filter?.find((f) => f.name === facets?.[i].name);
        const _facet = facets?.[i];

        if (_filter) {
            if (_filter.type === 'range' && _facet.type === 'range') {
                filter.push({
                    min: _facet.min,
                    max: _facet.max,
                    type: 'range',
                    show: true,
                    name: _filter.name,
                    attribute: _filter.attribute,
                    values: [
                        _facet?.selectedMin ?? 0,
                        _facet?.selectedMax ?? 10000000,
                    ],
                });
            } else if (
                _filter.type === 'checkbox' &&
                _facet.type === 'distinct'
            ) {
                filter.push({
                    type: 'checkbox',
                    show: true,
                    name: _filter.name,
                    items: _facet.items?.map((o) => ({
                        name: o.item,
                        count: o.count,
                    })),
                    attribute: _filter.attribute,
                    values: _filter.values,
                });
            }
        }
    }

    return {
        filter,
        items,
        relatedItems,
        count,
    };
};
